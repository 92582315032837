<template>
  <div>
    <add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :user-data="userData"
      @refetch-data="refetchData"
    />

    <!-- Filters -->
    <list-filters
      :role-filter.sync="roleFilter"
      :role-options="roleOptions"
      :im-filter.sync="imFilter"
      :mold-filter.sync="moldFilter"
      :plan-filter.sync="planFilter"
      :status-filter.sync="statusFilter"
      :im-options="imOptions"
      :mold-options="moldOptions"
    />

    <!-- Table Container Card -->
    <b-card no-body style="background: border-box; margin-top: -102px">
      <b-card-body>
        <b-row>
          <b-col cols="12" md="4" class="mb-md-0 mb-2" />

          <b-col cols="12" md="4" class="mb-md-0 mb-2" />

          <b-col cols="12" md="4" class="mb-md-0 mb-2" />

          <b-col cols="12" md="4" class="mb-md-0 mb-2" />
        </b-row>
        <b-row>
          <b-col cols="12" md="4" class="mb-md-0 mb-2" />

          <b-col cols="12" md="4" class="mb-md-0 mb-2" />

          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <div style="text-align-last: left">
              <b-button variant="primary" @click="ShowData()">
                <span class="text-nowrap">Filter</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <b-card no-body class="mb-0">
      <b-table-simple
        :busy.sync="isBusy"
        small
        caption-top
        responsive
        bordered
        outlined
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-button variant="outline-primary" disabled class="mr-1">
              <b-spinner small />
              Loading...
            </b-button>
          </div>
        </template>

        <caption>
          <div style="text-align-last: center">
            <h3>MAIN KPI PERFORMANCE REVIEW</h3>
          </div>
          <div style="text-align-last: center">
            <h5>Periode : {{ WeeklyOPR.data[0].mon }}</h5>
          </div>
        </caption>
        <caption>
          <h3>Fitting Karawang</h3>
        </caption>
        <colgroup>
          <col />
          <col />
        </colgroup>
        <colgroup>
          <col />
          <col />
          <col />
        </colgroup>
        <colgroup>
          <col />
          <col />
        </colgroup>
        <b-thead head-variant="light">
          <b-tr>
            <b-th
              rowspan="3"
              style="text-align: center; vertical-align: middle"
            >
              No
            </b-th>
            <b-th
              rowspan="3"
              style="text-align: center; vertical-align: middle"
            >
              Main KPI
            </b-th>
            <b-th
              rowspan="3"
              style="text-align: center; vertical-align: middle"
            >
              {{ WeeklyOPR.data[0].tahun }} <br />
              Target
            </b-th>
            <b-th
              rowspan="3"
              style="text-align: center; vertical-align: middle"
            >
              Satuan
            </b-th>
            <b-th
              rowspan="1"
              :colspan="WeeklyOPR.count"
              style="text-align: center"
            >
              {{ WeeklyOPR.data[0].mon }}
            </b-th>
            <b-th
              rowspan="3"
              style="text-align: center; vertical-align: middle"
            >
              MTD <br />
            </b-th>
            <b-th
              rowspan="3"
              colspan="2"
              style="text-align: center; vertical-align: middle"
            >
              Remark
            </b-th>
          </b-tr>
          <b-tr>
            <b-th
              v-for="(data, index) in WeeklyOPR.data"
              :key="data.id"
              style="text-align: center; vertical-align: middle"
            >
              <div v-if="data.active == 'YES'" style="background: #1565c0">
                W{{ index + 1 }} <br />
                {{ data.tgl_awal }}-{{ data.tgl_akhir }}
              </div>

              <div v-else>
                W{{ index + 1 }} <br />
                {{ data.tgl_awal }}-{{ data.tgl_akhir }}
              </div>
            </b-th>
          </b-tr>
        </b-thead>

        <b-tbody>
          <b-tr
            v-for="(data, index) in WeeklyOPRDetail.data"
            :key="data.id"
            style="height: 50px"
          >
            <b-th style="text-align: center" v-if="index == 0">
              {{ index + 1 }}
            </b-th>
            <b-th style="text-align: center" v-else-if="index == 7 || index == 8 || index == 9 || index == 10">
            </b-th>
            <b-th style="text-align: center" v-else-if="index >= 11">
              {{ index-4 }}
            </b-th>
            <b-th style="text-align: center" v-else-if="index > 1">
              {{ index }}
            </b-th>
            <b-th style="text-align: center" colspan="3" v-else> </b-th>
            <b-th
              v-if="data.link != '#' && index != 1"
              class="text-nowrap"
              style="text-align: left"
            >
              <b-link
                :to="{ name: data.link }"
                target="_blank"
                class="font-weight-bold"
              >
                {{ data.main_kpi }}
              </b-link>
            </b-th>
            <b-th
              v-else-if="data.link == '#' && index != 1"
              class="text-nowrap"
              style="text-align: left"
            >
              {{ data.main_kpi }}
            </b-th>
            <b-th
              class="text-nowrap"
              style="text-align: right"
              v-if="index != 1"
            >
              {{ data.target }}
            </b-th>
            <b-th
              v-if="index == 1"
              class="text-nowrap"
              style="text-align: center"
            >
              <b>{{ data.satuan }}/day</b>
            </b-th>
            <b-th v-else class="text-nowrap" style="text-align: center">
              <b>{{ data.satuan }}</b>
            </b-th>
            <b-th
              v-if="
                data.bulan == data.current_month && data.current_week == 'w1'
              "
              class="text-nowrap"
              style="text-align: right; background: #1565c0"
            >
              <div v-if="data.w1 === '' || data.w1 === null" />

              <div
                v-else-if="
                  data.target_report === 'lebihdari' &&
                  parseFloat(data.target) > parseFloat(data.w1)
                "
              >
                <b-badge
                  variant="light-danger"
                  style="font-size: 13px; background-color: red"
                >
                  <!-- <span class="font-weight-bolder text-danger">{{ data.w1 }}</span>  -->
                  <b-link
                    v-if="data.link_pbi !== null"
                    :to="{
                      name: data.link_pbi,
                      params: { mon: data.mon, week: '1' },
                    }"
                    target="_blank"
                    class=""
                  >
                    {{ data.w1 }}
                  </b-link>

                  <b-link v-else href="#" target="_blank" class="" disabled>
                    {{ data.w1 }}
                  </b-link>
                </b-badge>
              </div>
              <div
                v-else-if="
                  data.target_report === 'kurangdari' &&
                  parseFloat(data.target) < parseFloat(data.w1)
                "
              >
                <b-badge style="font-size: 13px; background-color: red">
                  <!-- <span class="font-weight-bolder text-danger">{{ data.w1 }}</span>  -->
                  <b-link
                    v-if="data.link_pbi !== null"
                    :to="{
                      name: data.link_pbi,
                      params: { mon: data.mon, week: '1' },
                    }"
                    target="_blank"
                    class=""
                  >
                    {{ data.w1 }}
                  </b-link>

                  <b-link v-else href="#" target="_blank" class="" disabled>
                    {{ data.w1 }}
                  </b-link>
                </b-badge>
              </div>
              <div v-else>
                <!-- <span class="font-weight-bolder">{{ data.w1 }}</span>  -->
                <b-badge
                  variant="light-success"
                  style="font-size: 13px; background-color: seagreen"
                >
                  <b-link
                    v-if="data.link_pbi !== null"
                    :to="{
                      name: data.link_pbi,
                      params: { mon: data.mon, week: '1' },
                    }"
                    target="_blank"
                    class=""
                  >
                    {{ data.w1 }}
                  </b-link>

                  <b-link v-else href="#" target="_blank" class="" disabled>
                    {{ data.w1 }}
                  </b-link>
                </b-badge>
              </div>
            </b-th>
            <b-th v-else class="text-nowrap" style="text-align: right">
              <div v-if="data.w1 === '' || data.w1 === null" />

              <div
                v-else-if="
                  data.target_report === 'lebihdari' &&
                  parseFloat(data.target) > parseFloat(data.w1)
                "
              >
                <b-badge
                  variant="light-danger"
                  style="font-size: 13px; background-color: red"
                >
                  <!-- <span class="font-weight-bolder text-danger">{{ data.w1 }}</span>  -->
                  <b-link
                    v-if="data.link_pbi !== null"
                    :to="{
                      name: data.link_pbi,
                      params: { mon: data.mon, week: '1' },
                    }"
                    target="_blank"
                    class=""
                  >
                    {{ data.w1 }}
                  </b-link>

                  <b-link v-else href="#" target="_blank" class="" disabled>
                    {{ data.w1 }}
                  </b-link>
                </b-badge>
              </div>
              <div
                v-else-if="
                  data.target_report === 'kurangdari' &&
                  parseFloat(data.target) < parseFloat(data.w1)
                "
              >
                <b-badge style="font-size: 13px; background-color: red">
                  <!-- <span class="font-weight-bolder text-danger">{{ data.w1 }}</span>  -->
                  <b-link
                    v-if="data.link_pbi !== null"
                    :to="{
                      name: data.link_pbi,
                      params: { mon: data.mon, week: '1' },
                    }"
                    target="_blank"
                    class=""
                  >
                    {{ data.w1 }}
                  </b-link>

                  <b-link v-else href="#" target="_blank" class="" disabled>
                    {{ data.w1 }}
                  </b-link>
                </b-badge>
              </div>
              <div v-else>
                <!-- <span class="font-weight-bolder">{{ data.w1 }}</span>  -->
                <b-badge
                  variant="light-success"
                  style="font-size: 13px; background-color: seagreen"
                >
                  <b-link
                    v-if="data.link_pbi !== null"
                    :to="{
                      name: data.link_pbi,
                      params: { mon: data.mon, week: '1' },
                    }"
                    target="_blank"
                    class=""
                  >
                    {{ data.w1 }}
                  </b-link>

                  <b-link v-else href="#" target="_blank" class="" disabled>
                    {{ data.w1 }}
                  </b-link>
                </b-badge>
              </div>
            </b-th>
            <!-- end week 1 -->

            <!-- start week 2 -->
            <b-th
              v-if="
                data.current_month == data.bulan && data.current_week == 'w2'
              "
              class="text-nowrap"
              style="text-align: right; background: #1565c0"
            >
              <div v-if="data.w2 === '' || data.w2 === null" />

              <div
                v-else-if="
                  data.target_report === 'lebihdari' &&
                  parseFloat(data.target) > parseFloat(data.w2)
                "
              >
                <b-badge
                  variant="light-danger"
                  style="font-size: 13px; background-color: red"
                >
                  <!-- <span class="font-weight-bolder text-danger">{{ data.w2 }}</span>  -->
                  <b-link
                    v-if="data.link_pbi !== null"
                    :to="{
                      name: data.link_pbi,
                      params: { mon: data.mon, week: '2' },
                    }"
                    target="_blank"
                    class=""
                  >
                    {{ data.w2 }}
                  </b-link>

                  <b-link v-else href="#" target="_blank" class="" disabled>
                    {{ data.w2 }}
                  </b-link>
                </b-badge>
              </div>
              <div
                v-else-if="
                  data.target_report === 'kurangdari' &&
                  parseFloat(data.target) < parseFloat(data.w2)
                "
              >
                <b-badge
                  variant="light-danger"
                  style="font-size: 13px; background-color: red"
                >
                  <!-- <span class="font-weight-bolder text-danger">{{ data.w2 }}</span>  -->
                  <b-link
                    v-if="data.link_pbi !== ''"
                    :to="{
                      name: data.link_pbi,
                      params: { mon: data.mon, week: '2' },
                    }"
                    target="_blank"
                    class=""
                  >
                    {{ data.w2 }}
                  </b-link>

                  <b-link v-else href="#" target="_blank" class="" disabled>
                    {{ data.w2 }}
                  </b-link>
                </b-badge>
              </div>
              <div v-else>
                <!-- <span class="font-weight-bolder">{{ data.w2 }}</span>  -->
                <b-badge
                  variant="light-success"
                  style="font-size: 13px; background-color: seagreen"
                >
                  <b-link
                    v-if="data.link_pbi !== ''"
                    :to="{
                      name: data.link_pbi,
                      params: { mon: data.mon, week: '2' },
                    }"
                    target="_blank"
                    class=""
                  >
                    {{ data.w2 }}
                  </b-link>

                  <b-link v-else href="#" target="_blank" class="" disabled>
                    {{ data.w2 }}
                  </b-link>
                </b-badge>
              </div>
            </b-th>

            <b-th v-else class="text-nowrap" style="text-align: right">
              <div v-if="data.w2 === '' || data.w2 === null" />

              <div
                v-else-if="
                  data.target_report === 'lebihdari' &&
                  parseFloat(data.target) > parseFloat(data.w2)
                "
              >
                <b-badge
                  variant="light-danger"
                  style="font-size: 13px; background-color: red"
                >
                  <!-- <span class="font-weight-bolder text-danger">{{ data.w2 }}</span>  -->
                  <b-link
                    v-if="data.link_pbi !== null"
                    :to="{
                      name: data.link_pbi,
                      params: { mon: data.mon, week: '2' },
                    }"
                    target="_blank"
                    class=""
                  >
                    {{ data.w2 }}
                  </b-link>

                  <b-link v-else href="#" target="_blank" class="" disabled>
                    {{ data.w2 }}
                  </b-link>
                </b-badge>
              </div>
              <div
                v-else-if="
                  data.target_report === 'kurangdari' &&
                  parseFloat(data.target) < parseFloat(data.w2)
                "
              >
                <b-badge
                  variant="light-danger"
                  style="font-size: 13px; background-color: red"
                >
                  <!-- <span class="font-weight-bolder text-danger">{{ data.w2 }}</span>  -->
                  <b-link
                    v-if="data.link_pbi !== ''"
                    :to="{
                      name: data.link_pbi,
                      params: { mon: data.mon, week: '2' },
                    }"
                    target="_blank"
                    class=""
                  >
                    {{ data.w2 }}
                  </b-link>

                  <b-link v-else href="#" target="_blank" class="" disabled>
                    {{ data.w2 }}
                  </b-link>
                </b-badge>
              </div>
              <div v-else>
                <!-- <span class="font-weight-bolder">{{ data.w2 }}</span>  -->
                <b-badge
                  variant="light-success"
                  style="font-size: 13px; background-color: seagreen"
                >
                  <b-link
                    v-if="data.link_pbi !== ''"
                    :to="{
                      name: data.link_pbi,
                      params: { mon: data.mon, week: '2' },
                    }"
                    target="_blank"
                    class=""
                  >
                    {{ data.w2 }}
                  </b-link>

                  <b-link v-else href="#" target="_blank" class="" disabled>
                    {{ data.w2 }}
                  </b-link>
                </b-badge>
              </div>
            </b-th>

            <!-- end week 2 -->
            <!-- start week 3 -->
            <b-th
              v-if="
                data.current_month == data.bulan && data.current_week == 'w3'
              "
              class="text-nowrap"
              style="text-align: right; background: #1565c0"
            >
              <div v-if="data.w3 === '' || data.w3 === null" />

              <div
                v-else-if="
                  data.target_report === 'lebihdari' &&
                  parseFloat(data.target) > parseFloat(data.w3)
                "
              >
                <b-badge
                  variant="light-danger"
                  style="font-size: 13px; background-color: red"
                >
                  <span class=""
                    ><b-link
                      :to="{
                        name: data.link_pbi,
                        params: { mon: data.mon, week: '3' },
                      }"
                      target="_blank"
                      class="danger"
                    >
                      {{ data.w3 }}
                    </b-link></span
                  >
                </b-badge>
              </div>
              <div
                v-else-if="
                  data.target_report === 'kurangdari' &&
                  parseFloat(data.target) < parseFloat(data.w3)
                "
              >
                <b-badge
                  variant="light-danger"
                  style="font-size: 13px; background-color: red"
                >
                  <span class=""
                    ><b-link
                      :to="{
                        name: data.link_pbi,
                        params: { mon: data.mon, week: '3' },
                      }"
                      target="_blank"
                      class=""
                    >
                      {{ data.w3 }}
                    </b-link></span
                  >
                </b-badge>
              </div>
              <div v-else>
                <span class="">
                  <b-badge
                    variant="light-success"
                    style="font-size: 13px; background-color: seagreen"
                  >
                    <b-link
                      :to="{
                        name: data.link_pbi,
                        params: { mon: data.mon, week: '3' },
                      }"
                      target="_blank"
                      class=""
                    >
                      {{ data.w3 }}
                    </b-link>
                  </b-badge></span
                >
              </div>
            </b-th>
            <b-th v-else class="text-nowrap" style="text-align: right">
              <div v-if="data.w3 === '' || data.w3 === null" />

              <div
                v-else-if="
                  data.target_report === 'lebihdari' &&
                  parseFloat(data.target) > parseFloat(data.w3)
                "
              >
                <b-badge
                  variant="light-danger"
                  style="font-size: 13px; background-color: red"
                >
                  <span class=""
                    ><b-link
                      :to="{
                        name: data.link_pbi,
                        params: { mon: data.mon, week: '3' },
                      }"
                      target="_blank"
                      class="danger"
                    >
                      {{ data.w3 }}
                    </b-link></span
                  >
                </b-badge>
              </div>
              <div
                v-else-if="
                  data.target_report === 'kurangdari' &&
                  parseFloat(data.target) < parseFloat(data.w3)
                "
              >
                <b-badge
                  variant="light-danger"
                  style="font-size: 13px; background-color: red"
                >
                  <span class=""
                    ><b-link
                      :to="{
                        name: data.link_pbi,
                        params: { mon: data.mon, week: '3' },
                      }"
                      target="_blank"
                      class=""
                    >
                      {{ data.w3 }}
                    </b-link></span
                  >
                </b-badge>
              </div>
              <div v-else>
                <span class="">
                  <b-badge
                    variant="light-success"
                    style="font-size: 13px; background-color: seagreen"
                  >
                    <b-link
                      :to="{
                        name: data.link_pbi,
                        params: { mon: data.mon, week: '3' },
                      }"
                      target="_blank"
                      class=""
                    >
                      {{ data.w3 }}
                    </b-link>
                  </b-badge></span
                >
              </div>
            </b-th>
            <!-- end kolom week 3 -->
            <!-- start kolom week 4 -->
            <b-th
              v-if="
                data.bulan == data.current_month && data.current_week == 'w4'
              "
              class="text-nowrap"
              style="text-align: right; background: #1565c0"
            >
              <div v-if="data.w4 === '' || data.w4 === null" />

              <div
                v-else-if="
                  data.target_report === 'lebihdari' &&
                  parseFloat(data.target) > parseFloat(data.w4)
                "
              >
                <b-badge
                  variant="light-danger"
                  style="font-size: 13px; background-color: red"
                >
                  <!-- <span class="font-weight-bolder text-danger">{{ data.w4 }}</span>  -->
                  <b-link
                    v-if="data.link_pbi !== null"
                    :to="{
                      name: data.link_pbi,
                      params: { mon: data.mon, week: '4' },
                    }"
                    target="_blank"
                    class=""
                  >
                    {{ data.w4 }}
                  </b-link>

                  <b-link v-else href="#" target="_blank" class="" disabled>
                    {{ data.w4 }}
                  </b-link>
                </b-badge>
              </div>
              <div
                v-else-if="
                  data.target_report === 'kurangdari' &&
                  parseFloat(data.target) < parseFloat(data.w4)
                "
              >
                <b-badge
                  variant="light-danger"
                  style="font-size: 13px; background-color: red"
                >
                  <!-- <span class="font-weight-bolder text-danger">{{ data.w4 }}</span>  -->
                  <b-link
                    v-if="data.link_pbi !== null"
                    :to="{
                      name: data.link_pbi,
                      params: { mon: data.mon, week: '4' },
                    }"
                    target="_blank"
                    class=""
                  >
                    {{ data.w4 }}
                  </b-link>

                  <b-link v-else href="#" target="_blank" class="" disabled>
                    {{ data.w4 }}
                  </b-link>
                </b-badge>
              </div>
              <div v-else>
                <!-- <span class="font-weight-bolder">{{ data.w4 }}</span>  -->
                <b-badge
                  variant="light-success"
                  style="font-size: 13px; background-color: seagreen"
                >
                  <b-link
                    v-if="data.link_pbi !== null"
                    :to="{
                      name: data.link_pbi,
                      params: { mon: data.mon, week: '4' },
                    }"
                    target="_blank"
                    class=""
                  >
                    {{ data.w4 }}
                  </b-link>

                  <b-link v-else href="#" target="_blank" class="" disabled>
                    {{ data.w4 }}
                  </b-link>
                </b-badge>
              </div>
            </b-th>
            <b-th v-else class="text-nowrap" style="text-align: right">
              <div v-if="data.w4 === '' || data.w4 === null" />

              <div
                v-else-if="
                  data.target_report === 'lebihdari' &&
                  parseFloat(data.target) > parseFloat(data.w4)
                "
              >
                <b-badge
                  variant="light-danger"
                  style="font-size: 13px; background-color: red"
                >
                  <!-- <span class="font-weight-bolder text-danger">{{ data.w4 }}</span>  -->
                  <b-link
                    v-if="data.link_pbi !== null"
                    :to="{
                      name: data.link_pbi,
                      params: { mon: data.mon, week: '4' },
                    }"
                    target="_blank"
                    class=""
                  >
                    {{ data.w4 }}
                  </b-link>

                  <b-link v-else href="#" target="_blank" class="" disabled>
                    {{ data.w4 }}
                  </b-link>
                </b-badge>
              </div>
              <div
                v-else-if="
                  data.target_report === 'kurangdari' &&
                  parseFloat(data.target) < parseFloat(data.w4)
                "
              >
                <b-badge
                  variant="light-danger"
                  style="font-size: 13px; background-color: red"
                >
                  <!-- <span class="font-weight-bolder text-danger">{{ data.w4 }}</span>  -->
                  <b-link
                    v-if="data.link_pbi !== null"
                    :to="{
                      name: data.link_pbi,
                      params: { mon: data.mon, week: '4' },
                    }"
                    target="_blank"
                    class=""
                  >
                    {{ data.w4 }}
                  </b-link>

                  <b-link v-else href="#" target="_blank" class="" disabled>
                    {{ data.w4 }}
                  </b-link>
                </b-badge>
              </div>
              <div v-else>
                <!-- <span class="font-weight-bolder">{{ data.w4 }}</span>  -->
                <b-badge
                  variant="light-success"
                  style="font-size: 13px; background-color: seagreen"
                >
                  <b-link
                    v-if="data.link_pbi !== null"
                    :to="{
                      name: data.link_pbi,
                      params: { mon: data.mon, week: '4' },
                    }"
                    target="_blank"
                    class=""
                  >
                    {{ data.w4 }}
                  </b-link>

                  <b-link v-else href="#" target="_blank" class="" disabled>
                    {{ data.w4 }}
                  </b-link>
                </b-badge>
              </div>
            </b-th>
            <!-- end kolom week 4 -->
            <!-- jika current periode maka beri hightlight pada kolom -->
            <b-th
              v-if="
                WeeklyOPRDetail.count === 5 &&
                data.bulan == data.current_month &&
                data.current_week == 'w5'
              "
              class="text-nowrap"
              style="text-align: right; background: #1565c0"
            >
              <div v-if="data.w5 === '' || data.w5 === null" />

              <div
                v-else-if="
                  data.target_report === 'lebihdari' &&
                  parseFloat(data.target) > parseFloat(data.w5)
                "
              >
                <b-badge
                  variant="light-danger"
                  style="font-size: 13px; background-color: red"
                >
                  <b-link
                    v-if="data.link_pbi !== null"
                    :to="{
                      name: data.link_pbi,
                      params: { mon: data.mon, week: '5' },
                    }"
                    target="_blank"
                    class=""
                  >
                    {{ data.w5 }}
                  </b-link>

                  <b-link v-else href="#" target="_blank" class="" disabled>
                    {{ data.w5 }}
                  </b-link>
                </b-badge>
              </div>
              <div
                v-else-if="
                  data.target_report === 'kurangdari' &&
                  parseFloat(data.target) < parseFloat(data.w5)
                "
              >
                <b-badge
                  variant="light-danger"
                  style="font-size: 13px; background-color: red"
                >
                  <b-link
                    v-if="data.link_pbi !== null"
                    :to="{
                      name: data.link_pbi,
                      params: { mon: data.mon, week: '5' },
                    }"
                    target="_blank"
                    class=""
                  >
                    {{ data.w5 }}
                  </b-link>

                  <b-link v-else href="#" target="_blank" class="" disabled>
                    {{ data.w5 }}
                  </b-link>
                </b-badge>
              </div>
              <div v-else>
                <b-badge
                  variant="light-success"
                  style="font-size: 13px; background-color: seagreen"
                >
                  <b-link
                    v-if="data.link_pbi !== null"
                    :to="{
                      name: data.link_pbi,
                      params: { mon: data.mon, week: '5' },
                    }"
                    target="_blank"
                    class=""
                  >
                    {{ data.w5 }}
                  </b-link>

                  <b-link v-else href="#" target="_blank" class="" disabled>
                    {{ data.w5 }}
                  </b-link>
                </b-badge>
              </div>
            </b-th>
            <b-th
              v-if="
                WeeklyOPRDetail.count === 5 &&
                data.bulan != data.current_month &&
                data.current_week != 'w5'
              "
              class="text-nowrap"
              style="text-align: right"
            >
              <div v-if="data.w5 === '' || data.w5 === null" />

              <div
                v-else-if="
                  data.target_report === 'lebihdari' &&
                  parseFloat(data.target) > parseFloat(data.w5)
                "
              >
                <b-badge
                  variant="light-danger"
                  style="font-size: 13px; background-color: red"
                >
                  <b-link
                    v-if="data.link_pbi !== null"
                    :to="{
                      name: data.link_pbi,
                      params: { mon: data.mon, week: '5' },
                    }"
                    target="_blank"
                    class=""
                  >
                    {{ data.w5 }}
                  </b-link>

                  <b-link v-else href="#" target="_blank" class="" disabled>
                    {{ data.w5 }}
                  </b-link>
                </b-badge>
              </div>
              <div
                v-else-if="
                  data.target_report === 'kurangdari' &&
                  parseFloat(data.target) < parseFloat(data.w5)
                "
              >
                <b-badge
                  variant="light-danger"
                  style="font-size: 13px; background-color: red"
                >
                  <b-link
                    v-if="data.link_pbi !== null"
                    :to="{
                      name: data.link_pbi,
                      params: { mon: data.mon, week: '5' },
                    }"
                    target="_blank"
                    class=""
                  >
                    {{ data.w5 }}
                  </b-link>

                  <b-link v-else href="#" target="_blank" class="" disabled>
                    {{ data.w5 }}
                  </b-link>
                </b-badge>
              </div>
              <div v-else>
                <b-badge
                  variant="light-success"
                  style="font-size: 13px; background-color: seagreen"
                >
                  <b-link
                    v-if="data.link_pbi !== null"
                    :to="{
                      name: data.link_pbi,
                      params: { mon: data.mon, week: '5' },
                    }"
                    target="_blank"
                    class=""
                  >
                    {{ data.w5 }}
                  </b-link>

                  <b-link v-else href="#" target="_blank" class="" disabled>
                    {{ data.w5 }}
                  </b-link>
                </b-badge>
              </div>
            </b-th>
            <b-th
              v-if="
                WeeklyOPRDetail.count === 5 &&
                data.bulan == data.current_month &&
                data.current_week != 'w5'
              "
              class="text-nowrap"
              style="text-align: right"
            >
              <div v-if="data.w5 === '' || data.w5 === null" />

              <div
                v-else-if="
                  data.target_report === 'lebihdari' &&
                  parseFloat(data.target) > parseFloat(data.w5)
                "
              >
                <b-badge
                  variant="light-danger"
                  style="font-size: 13px; background-color: red"
                >
                  <b-link
                    v-if="data.link_pbi !== null"
                    :to="{
                      name: data.link_pbi,
                      params: { mon: data.mon, week: '5' },
                    }"
                    target="_blank"
                    class=""
                  >
                    {{ data.w5 }}
                  </b-link>

                  <b-link v-else href="#" target="_blank" class="" disabled>
                    {{ data.w5 }}
                  </b-link>
                </b-badge>
              </div>
              <div
                v-else-if="
                  data.target_report === 'kurangdari' &&
                  parseFloat(data.target) < parseFloat(data.w5)
                "
              >
                <b-badge
                  variant="light-danger"
                  style="font-size: 13px; background-color: red"
                >
                  <b-link
                    v-if="data.link_pbi !== null"
                    :to="{
                      name: data.link_pbi,
                      params: { mon: data.mon, week: '5' },
                    }"
                    target="_blank"
                    class=""
                  >
                    {{ data.w5 }}
                  </b-link>

                  <b-link v-else href="#" target="_blank" class="" disabled>
                    {{ data.w5 }}
                  </b-link>
                </b-badge>
              </div>
              <div v-else>
                <b-badge
                  variant="light-success"
                  style="font-size: 13px; background-color: seagreen"
                >
                  <b-link
                    v-if="data.link_pbi !== null"
                    :to="{
                      name: data.link_pbi,
                      params: { mon: data.mon, week: '5' },
                    }"
                    target="_blank"
                    class=""
                  >
                    {{ data.w5 }}
                  </b-link>

                  <b-link v-else href="#" target="_blank" class="" disabled>
                    {{ data.w5 }}
                  </b-link>
                </b-badge>
              </div>
            </b-th>
            <b-th
              v-if="data.rumus_mtd === 'Summary' && WeeklyOPR.count === 4"
              style="text-align: right"
              class="text-nowrap"
            >
              <div
                v-if="
                  data.target_report === 'lebihdari' &&
                  parseFloat(data.w1.replaceAll(',', '')) +
                    parseFloat(data.w2.replaceAll(',', '')) +
                    parseFloat(data.w3.replaceAll(',', '')) +
                    parseFloat(data.w4.replaceAll(',', '')) >=
                    data.target
                "
              >
                <b-badge style="font-size: 13px; background-color: red">
                  {{
                    (
                      parseFloat(data.w1.replaceAll(",", "")) +
                      parseFloat(data.w2.replaceAll(",", "")) +
                      parseFloat(data.w3.replaceAll(",", "")) +
                      parseFloat(data.w4.replaceAll(",", ""))
                    ).toFixed(2)
                  }}
                </b-badge>
              </div>
              <div
                v-else-if="
                  data.target_report === 'kurangdari' &&
                  parseFloat(data.w1.replaceAll(',', '')) +
                    parseFloat(data.w2.replaceAll(',', '')) +
                    parseFloat(data.w3.replaceAll(',', '')) +
                    parseFloat(data.w4.replaceAll(',', '')) <=
                    data.target
                "
              >
                <b-badge style="font-size: 13px; background-color: red">
                  {{
                    (
                      parseFloat(data.w1.replaceAll(",", "")) +
                      parseFloat(data.w2.replaceAll(",", "")) +
                      parseFloat(data.w3.replaceAll(",", "")) +
                      parseFloat(data.w4.replaceAll(",", ""))
                    ).toFixed(2)
                  }}
                </b-badge>
              </div>
              <div v-else>
                <b-badge style="font-size: 13px; background-color: red">
                  {{
                    (
                      parseFloat(data.w1.replaceAll(",", "")) +
                      parseFloat(data.w2.replaceAll(",", "")) +
                      parseFloat(data.w3.replaceAll(",", "")) +
                      parseFloat(data.w4.replaceAll(",", ""))
                    ).toFixed(2)
                  }}
                </b-badge>
              </div>
            </b-th>
            <b-th
              v-else-if="data.rumus_mtd === 'Summary' && WeeklyOPR.count === 5"
              style="text-align: right"
              class="text-nowrap"
            >
              <div
                v-if="
                  data.target_report === 'lebihdari' &&
                  parseFloat(data.w1.replaceAll(',', '')) +
                    parseFloat(data.w2.replaceAll(',', '')) +
                    parseFloat(data.w3.replaceAll(',', '')) +
                    parseFloat(data.w4.replaceAll(',', '')) +
                    parseFloat(data.w5.replaceAll(',', '')) >=
                    data.target
                "
              >
                <b-badge style="font-size: 13px; background-color: red">
                  {{
                    (
                      parseFloat(data.w1.replaceAll(",", "")) +
                      parseFloat(data.w2.replaceAll(",", "")) +
                      parseFloat(data.w3.replaceAll(",", "")) +
                      parseFloat(data.w4.replaceAll(",", "")) +
                      parseFloat(data.w5.replaceAll(",", ""))
                    ).toFixed(2)
                  }}
                </b-badge>
              </div>
              <div
                v-else-if="
                  data.target_report === 'kurangdari' &&
                  parseFloat(data.w1.replaceAll(',', '')) +
                    parseFloat(data.w2.replaceAll(',', '')) +
                    parseFloat(data.w3.replaceAll(',', '')) +
                    parseFloat(data.w4.replaceAll(',', '')) +
                    parseFloat(data.w5.replaceAll(',', '')) <=
                    data.target
                "
              >
                <b-badge style="font-size: 13px; background-color: seagreen">
                  {{
                    (
                      parseFloat(data.w1.replaceAll(",", "")) +
                      parseFloat(data.w2.replaceAll(",", "")) +
                      parseFloat(data.w3.replaceAll(",", "")) +
                      parseFloat(data.w4.replaceAll(",", "")) +
                      parseFloat(data.w5.replaceAll(",", ""))
                    ).toFixed(2)
                  }}
                </b-badge>
              </div>
              <div v-else>
                <b-badge
                  style="font-size: 13px; background-color: red; color: white"
                >
                  {{
                    (
                      parseFloat(data.w1.replaceAll(",", "")) +
                      parseFloat(data.w2.replaceAll(",", "")) +
                      parseFloat(data.w3.replaceAll(",", "")) +
                      parseFloat(data.w4.replaceAll(",", "")) +
                      parseFloat(data.w5.replaceAll(",", ""))
                    ).toFixed(2)
                  }}
                </b-badge>
              </div>
            </b-th>
            <b-th
              v-else-if="data.rumus_mtd === 'Average' && WeeklyOPR.count === 4"
              style="text-align: right"
              class="text-nowrap"
            >
              <div
                v-if="
                  data.target_report === 'lebihdari' &&
                  (parseFloat(data.w1.replaceAll(',', '')) +
                    parseFloat(data.w2.replaceAll(',', '')) +
                    parseFloat(data.w3.replaceAll(',', '')) +
                    parseFloat(data.w4.replaceAll(',', ''))) /
                    4 >=
                    data.target
                "
              >
                <b-badge style="font-size: 13px; background-color: red">
                  {{
                    (
                      (parseFloat(data.w1.replaceAll(",", "")) +
                        parseFloat(data.w2.replaceAll(",", "")) +
                        parseFloat(data.w3.replaceAll(",", "")) +
                        parseFloat(data.w4.replaceAll(",", ""))) /
                      4
                    ).toFixed(2)
                  }}
                </b-badge>
              </div>
              <div
                v-else-if="
                  data.target_report === 'kurangdari' &&
                  (parseFloat(data.w1.replaceAll(',', '')) +
                    parseFloat(data.w2.replaceAll(',', '')) +
                    parseFloat(data.w3.replaceAll(',', '')) +
                    parseFloat(data.w4.replaceAll(',', ''))) /
                    4 <=
                    data.target
                "
              >
                <b-badge style="font-size: 13px; background-color: red">
                  {{
                    (
                      (parseFloat(data.w1.replaceAll(",", "")) +
                        parseFloat(data.w2.replaceAll(",", "")) +
                        parseFloat(data.w3.replaceAll(",", "")) +
                        parseFloat(data.w4.replaceAll(",", ""))) /
                      4
                    ).toFixed(2)
                  }}
                </b-badge>
              </div>
              <div v-else>
                <b-badge
                  style="font-size: 13px; background-color: red; color: white"
                >
                  {{
                    (
                      (parseFloat(data.w1.replaceAll(",", "")) +
                        parseFloat(data.w2.replaceAll(",", "")) +
                        parseFloat(data.w3.replaceAll(",", "")) +
                        parseFloat(data.w4.replaceAll(",", ""))) /
                      4
                    ).toFixed(2)
                  }}
                </b-badge>
              </div>
            </b-th>
            <b-th
              v-else-if="data.rumus_mtd === 'Average' && WeeklyOPR.count === 5"
              style="text-align: right"
              class="text-nowrap"
            >
              <div
                v-if="
                  data.target_report === 'lebihdari' &&
                  (parseFloat(data.w1.replaceAll(',', '')) +
                    parseFloat(data.w2.replaceAll(',', '')) +
                    parseFloat(data.w3.replaceAll(',', '')) +
                    parseFloat(data.w4.replaceAll(',', '')) +
                    parseFloat(data.w5.replaceAll(',', ''))) /
                    5 >=
                    data.target
                "
              >
                <b-badge style="font-size: 13px; background-color: seagreen">
                  {{
                    (
                      (parseFloat(data.w1.replaceAll(",", "")) +
                        parseFloat(data.w2.replaceAll(",", "")) +
                        parseFloat(data.w3.replaceAll(",", "")) +
                        parseFloat(data.w4.replaceAll(",", "")) +
                        parseFloat(data.w5.replaceAll(",", ""))) /
                      5
                    ).toFixed(2)
                  }}
                </b-badge>
              </div>
              <div
                v-else-if="
                  data.target_report === 'kurangdari' &&
                  (parseFloat(data.w1.replaceAll(',', '')) +
                    parseFloat(data.w2.replaceAll(',', '')) +
                    parseFloat(data.w3.replaceAll(',', '')) +
                    parseFloat(data.w4.replaceAll(',', '')) +
                    parseFloat(data.w5)) /
                    5 <=
                    data.target
                "
              >
                <b-badge style="font-size: 13px; background-color: seagreen">
                  {{
                    (
                      (parseFloat(data.w1.replaceAll(",", "")) +
                        parseFloat(data.w2.replaceAll(",", "")) +
                        parseFloat(data.w3.replaceAll(",", "")) +
                        parseFloat(data.w4.replaceAll(",", "")) +
                        parseFloat(data.w5.replaceAll(",", ""))) /
                      5
                    ).toFixed(2)
                  }}
                </b-badge>
              </div>
              <div v-else>
                <b-badge
                  style="font-size: 13px; background-color: red; color: white"
                >
                  {{
                    (
                      (parseFloat(data.w1.replaceAll(",", "")) +
                        parseFloat(data.w2.replaceAll(",", "")) +
                        parseFloat(data.w3.replaceAll(",", "")) +
                        parseFloat(data.w4.replaceAll(",", "")) +
                        parseFloat(data.w5.replaceAll(",", ""))) /
                      5
                    ).toFixed(2)
                  }}
                </b-badge>
              </div>
            </b-th>
            <b-th class="text-nowrap" style="background: top">
              <!-- <b-form-textarea
                id="textarea-default"
                rows="3"
                :value="data.remark"
                readonly
                style="border:none;"
              /> -->
              <pre style="background: fixed">{{ data.remark }}</pre>
            </b-th>
            <b-th
              class="text-nowrap"
              style="
                text-align: right;
                vertical-align: middle;
                border-left: hidden;
              "
              ><feather-icon
                icon="EditIcon"
                class="cursor-pointer"
                :class="`text-primary`"
                size="17"
                @click="EditData(data)"
              />
            </b-th>
          </b-tr>
        </b-tbody>
        <!-- <b-tfoot>
          <b-tr>
            <b-td
              colspan="10"
              class="text-left"
            >
              Daily Output prediction: 20661.314 kg <br>
              Daily kWh prediction: 8493.93 kWh <br>
              Daily Energy Rate Prediction: 0,411 kWh/kg <br>
            </b-td>
          </b-tr>
        </b-tfoot> -->
      </b-table-simple>
    </b-card>
    <br />
    <b-card no-body class="mb-0">
      <b-row>
        <div style="margin-left: 15px">
          <!-- types -->
          <app-collapse accordion :type="collapseType">
            <app-collapse-item title="Monitoring">
              <b-table-simple
                :busy.sync="isBusy"
                small
                caption-top
                responsive
                bordered
                outlined
              >
                <template #table-busy>
                  <div class="text-center text-primary my-2">
                    <b-button variant="outline-primary" disabled class="mr-1">
                      <b-spinner small />
                      Loading...
                    </b-button>
                  </div>
                </template>

                <!-- <caption> <div style="text-align-last: center">
                  <h3>MAIN KPI PERFORMANCE REVIEW</h3>
                </div><div style="text-align-last: center">
                  <h5>Periode : {{ WeeklyOPR.data[0].mon }}</h5>
                </div></caption>
                <caption><h3>Fitting Karawang</h3></caption> -->
                <colgroup>
                  <col />
                  <col />
                </colgroup>
                <colgroup>
                  <col />
                  <col />
                  <col />
                </colgroup>
                <colgroup>
                  <col />
                  <col />
                </colgroup>
                <b-thead head-variant="light">
                  <b-tr>
                    <b-th
                      rowspan="3"
                      style="text-align: center; vertical-align: middle"
                    >
                      No
                    </b-th>
                    <b-th
                      rowspan="3"
                      style="text-align: center; vertical-align: middle"
                    >
                      Main KPI
                    </b-th>
                    <b-th
                      rowspan="3"
                      style="text-align: center; vertical-align: middle"
                    >
                      {{ WeeklyOPR.data[0].tahun }} <br />
                      Target
                    </b-th>
                    <b-th
                      rowspan="3"
                      style="text-align: center; vertical-align: middle"
                    >
                      Satuan
                    </b-th>
                    <b-th
                      rowspan="1"
                      :colspan="WeeklyOPR.count"
                      style="text-align: center"
                    >
                      {{ WeeklyOPR.data[0].mon }}
                    </b-th>
                    <b-th
                      rowspan="3"
                      style="text-align: center; vertical-align: middle"
                    >
                      MTD <br />
                    </b-th>
                    <b-th
                      rowspan="3"
                      colspan="2"
                      style="text-align: center; vertical-align: middle"
                    >
                      Remark
                    </b-th>
                  </b-tr>
                  <b-tr>
                    <b-th
                      v-for="(data, index) in WeeklyOPR.data"
                      :key="data.id"
                      style="text-align: center; vertical-align: middle"
                    >
                      <div
                        v-if="data.active == 'YES'"
                        style="background: #1565c0"
                      >
                        W{{ index + 1 }} <br />
                        {{ data.tgl_awal }}-{{ data.tgl_akhir }}
                      </div>

                      <div v-else>
                        W{{ index + 1 }} <br />
                        {{ data.tgl_awal }}-{{ data.tgl_akhir }}
                      </div>
                    </b-th>
                  </b-tr>
                </b-thead>

                <b-tbody>
                  <b-tr
                    v-for="(data, index) in WeeklyOPRDetail2.data"
                    :key="data.id"
                    style="height: 50px"
                  >
                    <b-th style="text-align: center">
                      {{ index + 1 }}
                    </b-th>
                    <b-th
                      v-if="data.link != '#'"
                      class="text-nowrap"
                      style="text-align: left"
                    >
                      <b-link
                        :to="{ name: data.link }"
                        target="_blank"
                        class="font-weight-bold"
                      >
                        {{ data.main_kpi }}
                      </b-link>
                    </b-th>
                    <b-th v-else class="text-nowrap" style="text-align: left">
                      {{ data.main_kpi }}
                    </b-th>
                    <b-th class="text-nowrap" style="text-align: right">
                      {{ data.target }}
                    </b-th>
                    <b-th class="text-nowrap" style="text-align: center">
                      <b>{{ data.satuan }}</b>
                    </b-th>
                    <b-th
                      v-if="
                        data.bulan == data.current_month &&
                        data.current_week == 'w1'
                      "
                      class="text-nowrap"
                      style="text-align: right; background: #1565c0"
                    >
                      <div v-if="data.w1 === '' || data.w1 === null" />

                      <div
                        v-else-if="
                          data.target_report === 'lebihdari' &&
                          parseFloat(data.target) > parseFloat(data.w1)
                        "
                      >
                        <b-badge
                          variant="light-danger"
                          style="font-size: 13px; background-color: red"
                        >
                          <!-- <span class="font-weight-bolder text-danger">{{ data.w1 }}</span>  -->
                          <b-link
                            v-if="data.link_pbi !== null"
                            :to="{
                              name: data.link_pbi,
                              params: { mon: data.mon, week: '1' },
                            }"
                            target="_blank"
                            class=""
                          >
                            {{ data.w1 }}
                          </b-link>

                          <b-link
                            v-else
                            href="#"
                            target="_blank"
                            class=""
                            disabled
                          >
                            {{ data.w1 }}
                          </b-link>
                        </b-badge>
                      </div>
                      <div
                        v-else-if="
                          data.target_report === 'kurangdari' &&
                          parseFloat(data.target) < parseFloat(data.w1)
                        "
                      >
                        <b-badge
                          variant="light-danger"
                          style="font-size: 13px; background-color: red"
                        >
                          <!-- <span class="font-weight-bolder text-danger">{{ data.w1 }}</span>  -->
                          <b-link
                            v-if="data.link_pbi !== null"
                            :to="{
                              name: data.link_pbi,
                              params: { mon: data.mon, week: '1' },
                            }"
                            target="_blank"
                            class=""
                          >
                            {{ data.w1 }}
                          </b-link>

                          <b-link
                            v-else
                            href="#"
                            target="_blank"
                            class=""
                            disabled
                          >
                            {{ data.w1 }}
                          </b-link>
                        </b-badge>
                      </div>
                      <div v-else>
                        <!-- <span class="font-weight-bolder">{{ data.w1 }}</span>  -->
                        <b-badge
                          variant="light-success"
                          style="font-size: 13px; background-color: seagreen"
                        >
                          <b-link
                            v-if="data.link_pbi !== null"
                            :to="{
                              name: data.link_pbi,
                              params: { mon: data.mon, week: '1' },
                            }"
                            target="_blank"
                            class=""
                          >
                            {{ data.w1 }}
                          </b-link>

                          <b-link
                            v-else
                            href="#"
                            target="_blank"
                            class=""
                            disabled
                          >
                            {{ data.w1 }}
                          </b-link>
                        </b-badge>
                      </div>
                    </b-th>
                    <b-th v-else class="text-nowrap" style="text-align: right">
                      <div v-if="data.w1 === '' || data.w1 === null" />

                      <div
                        v-else-if="
                          data.target_report === 'lebihdari' &&
                          parseFloat(data.target) > parseFloat(data.w1)
                        "
                      >
                        <b-badge
                          variant="light-danger"
                          style="font-size: 13px; background-color: red"
                        >
                          <!-- <span class="font-weight-bolder text-danger">{{ data.w1 }}</span>  -->
                          <b-link
                            v-if="data.link_pbi !== null"
                            :to="{
                              name: data.link_pbi,
                              params: { mon: data.mon, week: '1' },
                            }"
                            target="_blank"
                            class=""
                          >
                            {{ data.w1 }}
                          </b-link>

                          <b-link
                            v-else
                            href="#"
                            target="_blank"
                            class=""
                            disabled
                          >
                            {{ data.w1 }}
                          </b-link>
                        </b-badge>
                      </div>
                      <div
                        v-else-if="
                          data.target_report === 'kurangdari' &&
                          parseFloat(data.target) < parseFloat(data.w1)
                        "
                      >
                        <b-badge
                          variant="light-danger"
                          style="font-size: 13px; background-color: red"
                        >
                          <!-- <span class="font-weight-bolder text-danger">{{ data.w1 }}</span>  -->
                          <b-link
                            v-if="data.link_pbi !== null"
                            :to="{
                              name: data.link_pbi,
                              params: { mon: data.mon, week: '1' },
                            }"
                            target="_blank"
                            class=""
                          >
                            {{ data.w1 }}
                          </b-link>

                          <b-link
                            v-else
                            href="#"
                            target="_blank"
                            class=""
                            disabled
                          >
                            {{ data.w1 }}
                          </b-link>
                        </b-badge>
                      </div>
                      <div v-else>
                        <!-- <span class="font-weight-bolder">{{ data.w1 }}</span>  -->
                        <b-badge
                          variant="light-success"
                          style="font-size: 13px; background-color: seagreen"
                        >
                          <b-link
                            v-if="data.link_pbi !== null"
                            :to="{
                              name: data.link_pbi,
                              params: { mon: data.mon, week: '1' },
                            }"
                            target="_blank"
                            class=""
                          >
                            {{ data.w1 }}
                          </b-link>

                          <b-link
                            v-else
                            href="#"
                            target="_blank"
                            class=""
                            disabled
                          >
                            {{ data.w1 }}
                          </b-link>
                        </b-badge>
                      </div>
                    </b-th>
                    <!-- end week 1 bawah -->
                    <!-- start week 2 bawah -->
                    <b-th
                      v-if="
                        data.current_month == data.bulan &&
                        data.current_week == 'w2'
                      "
                      class="text-nowrap"
                      style="text-align: right; background: #1565c0"
                    >
                      <div v-if="data.w2 === '' || data.w2 === null" />

                      <div
                        v-else-if="
                          data.target_report === 'lebihdari' &&
                          parseFloat(data.target) > parseFloat(data.w2)
                        "
                      >
                        <b-badge
                          variant="light-danger"
                          style="font-size: 13px; background-color: red"
                        >
                          <!-- <span class="font-weight-bolder text-danger">{{ data.w2 }}</span>  -->
                          <b-link
                            v-if="data.link_pbi !== null"
                            :to="{
                              name: data.link_pbi,
                              params: { mon: data.mon, week: '2' },
                            }"
                            target="_blank"
                            class=""
                          >
                            {{ data.w2 }}
                          </b-link>

                          <b-link
                            v-else
                            href="#"
                            target="_blank"
                            class=""
                            disabled
                          >
                            {{ data.w2 }}
                          </b-link>
                        </b-badge>
                      </div>
                      <div
                        v-else-if="
                          data.target_report === 'kurangdari' &&
                          parseFloat(data.target) < parseFloat(data.w2)
                        "
                      >
                        <b-badge
                          variant="light-danger"
                          style="font-size: 13px; background-color: red"
                        >
                          <!-- <span class="font-weight-bolder text-danger">{{ data.w2 }}</span>  -->
                          <b-link
                            v-if="data.link_pbi !== null"
                            :to="{
                              name: data.link_pbi,
                              params: { mon: data.mon, week: '2' },
                            }"
                            target="_blank"
                            class=""
                          >
                            {{ data.w2 }}
                          </b-link>

                          <b-link
                            v-else
                            href="#"
                            target="_blank"
                            class=""
                            disabled
                          >
                            {{ data.w2 }}
                          </b-link>
                        </b-badge>
                      </div>
                      <div v-else>
                        <!-- <span class="font-weight-bolder">{{ data.w2 }}</span>  -->
                        <b-badge
                          variant="light-success"
                          style="font-size: 13px; background-color: seagreen"
                        >
                          <b-link
                            v-if="data.link_pbi !== null"
                            :to="{
                              name: data.link_pbi,
                              params: { mon: data.mon, week: '2' },
                            }"
                            target="_blank"
                            class=""
                          >
                            {{ data.w2 }}
                          </b-link>

                          <b-link
                            v-else
                            href="#"
                            target="_blank"
                            class=""
                            disabled
                          >
                            {{ data.w2 }}
                          </b-link>
                        </b-badge>
                      </div>
                    </b-th>

                    <b-th v-else class="text-nowrap" style="text-align: right">
                      <div v-if="data.w2 === '' || data.w2 === null" />

                      <div
                        v-else-if="
                          data.target_report === 'lebihdari' &&
                          parseFloat(data.target) > parseFloat(data.w2)
                        "
                      >
                        <b-badge
                          variant="light-danger"
                          style="font-size: 13px; background-color: red"
                        >
                          <!-- <span class="font-weight-bolder text-danger">{{ data.w2 }}</span>  -->
                          <b-link
                            v-if="data.link_pbi !== null"
                            :to="{
                              name: data.link_pbi,
                              params: { mon: data.mon, week: '2' },
                            }"
                            target="_blank"
                            class=""
                          >
                            {{ data.w2 }}
                          </b-link>

                          <b-link
                            v-else
                            href="#"
                            target="_blank"
                            class=""
                            disabled
                          >
                            {{ data.w2 }}
                          </b-link>
                        </b-badge>
                      </div>
                      <div
                        v-else-if="
                          data.target_report === 'kurangdari' &&
                          parseFloat(data.target) < parseFloat(data.w2)
                        "
                      >
                        <b-badge
                          variant="light-danger"
                          style="font-size: 13px; background-color: red"
                        >
                          <!-- <span class="font-weight-bolder text-danger">{{ data.w2 }}</span>  -->
                          <b-link
                            v-if="data.link_pbi !== null"
                            :to="{
                              name: data.link_pbi,
                              params: { mon: data.mon, week: '2' },
                            }"
                            target="_blank"
                            class=""
                          >
                            {{ data.w2 }}
                          </b-link>

                          <b-link
                            v-else
                            href="#"
                            target="_blank"
                            class=""
                            disabled
                          >
                            {{ data.w2 }}
                          </b-link>
                        </b-badge>
                      </div>
                      <div v-else>
                        <!-- <span class="font-weight-bolder">{{ data.w2 }}</span>  -->
                        <b-badge
                          variant="light-success"
                          style="font-size: 13px; background-color: seagreen"
                        >
                          <b-link
                            v-if="data.link_pbi !== null"
                            :to="{
                              name: data.link_pbi,
                              params: { mon: data.mon, week: '2' },
                            }"
                            target="_blank"
                            class=""
                          >
                            {{ data.w2 }}
                          </b-link>

                          <b-link
                            v-else
                            href="#"
                            target="_blank"
                            class=""
                            disabled
                          >
                            {{ data.w2 }}
                          </b-link>
                        </b-badge>
                      </div>
                    </b-th>
                    <!-- end week 2 bawah -->
                    <!-- start week 3 bawah -->
                    <b-th
                      v-if="
                        data.current_month == data.bulan &&
                        data.current_week == 'w3'
                      "
                      class="text-nowrap"
                      style="text-align: right; background: #1565c0"
                    >
                      <div v-if="data.w3 === '' || data.w3 === null" />

                      <div
                        v-else-if="
                          data.target_report === 'lebihdari' &&
                          parseFloat(data.target) > parseFloat(data.w3)
                        "
                      >
                        <b-badge
                          variant="light-danger"
                          style="font-size: 13px; background-color: red"
                        >
                          <!-- <span class="font-weight-bolder text-danger">{{ data.w3 }}</span>  -->
                          <b-link
                            v-if="data.link_pbi !== null"
                            :to="{
                              name: data.link_pbi,
                              params: { mon: data.mon, week: '3' },
                            }"
                            target="_blank"
                            class=""
                          >
                            {{ data.w3 }}
                          </b-link>

                          <b-link
                            v-else
                            href="#"
                            target="_blank"
                            class=""
                            disabled
                          >
                            {{ data.w3 }}
                          </b-link>
                        </b-badge>
                      </div>
                      <div
                        v-else-if="
                          data.target_report === 'kurangdari' &&
                          parseFloat(data.target) < parseFloat(data.w3)
                        "
                      >
                        <b-badge
                          variant="light-danger"
                          style="font-size: 13px; background-color: red"
                        >
                          <!-- <span class="font-weight-bolder text-danger">{{ data.w3 }}</span>  -->
                          <b-link
                            v-if="data.link_pbi !== null"
                            :to="{
                              name: data.link_pbi,
                              params: { mon: data.mon, week: '3' },
                            }"
                            target="_blank"
                            class=""
                          >
                            {{ data.w3 }}
                          </b-link>

                          <b-link
                            v-else
                            href="#"
                            target="_blank"
                            class=""
                            disabled
                          >
                            {{ data.w3 }}
                          </b-link>
                        </b-badge>
                      </div>
                      <div v-else>
                        <!-- <span class="font-weight-bolder">{{ data.w3 }}</span>  -->
                        <b-badge
                          variant="light-success"
                          style="font-size: 13px; background-color: seagreen"
                        >
                          <b-link
                            v-if="data.link_pbi !== null"
                            :to="{
                              name: data.link_pbi,
                              params: { mon: data.mon, week: '3' },
                            }"
                            target="_blank"
                            class=""
                          >
                            {{ data.w3 }}
                          </b-link>

                          <b-link
                            v-else
                            href="#"
                            target="_blank"
                            class=""
                            disabled
                          >
                            {{ data.w3 }}
                          </b-link>
                        </b-badge>
                      </div>
                    </b-th>

                    <b-th v-else class="text-nowrap" style="text-align: right">
                      <div v-if="data.w3 === '' || data.w3 === null" />

                      <div
                        v-else-if="
                          data.target_report === 'lebihdari' &&
                          parseFloat(data.target) > parseFloat(data.w3)
                        "
                      >
                        <b-badge
                          variant="light-danger"
                          style="font-size: 13px; background-color: red"
                        >
                          <!-- <span class="font-weight-bolder text-danger">{{ data.w3 }}</span>  -->
                          <b-link
                            v-if="data.link_pbi !== null"
                            :to="{
                              name: data.link_pbi,
                              params: { mon: data.mon, week: '3' },
                            }"
                            target="_blank"
                            class=""
                          >
                            {{ data.w3 }}
                          </b-link>

                          <b-link
                            v-else
                            href="#"
                            target="_blank"
                            class=""
                            disabled
                          >
                            {{ data.w3 }}
                          </b-link>
                        </b-badge>
                      </div>
                      <div
                        v-else-if="
                          data.target_report === 'kurangdari' &&
                          parseFloat(data.target) < parseFloat(data.w3)
                        "
                      >
                        <b-badge
                          variant="light-danger"
                          style="font-size: 13px; background-color: red"
                        >
                          <!-- <span class="font-weight-bolder text-danger">{{ data.w3 }}</span>  -->
                          <b-link
                            v-if="data.link_pbi !== null"
                            :to="{
                              name: data.link_pbi,
                              params: { mon: data.mon, week: '3' },
                            }"
                            target="_blank"
                            class=""
                          >
                            {{ data.w3 }}
                          </b-link>

                          <b-link
                            v-else
                            href="#"
                            target="_blank"
                            class=""
                            disabled
                          >
                            {{ data.w3 }}
                          </b-link>
                        </b-badge>
                      </div>
                      <div v-else>
                        <!-- <span class="font-weight-bolder">{{ data.w3 }}</span>  -->
                        <b-badge
                          variant="light-success"
                          style="font-size: 13px; background-color: seagreen"
                        >
                          <b-link
                            v-if="data.link_pbi !== null"
                            :to="{
                              name: data.link_pbi,
                              params: { mon: data.mon, week: '3' },
                            }"
                            target="_blank"
                            class=""
                          >
                            {{ data.w3 }}
                          </b-link>

                          <b-link
                            v-else
                            href="#"
                            target="_blank"
                            class=""
                            disabled
                          >
                            {{ data.w3 }}
                          </b-link>
                        </b-badge>
                      </div>
                    </b-th>
                    <!-- end week 3 bawah -->
                    <!-- start week 4 bawah -->
                    <b-th
                      v-if="
                        data.bulan == data.current_month &&
                        data.current_week == 'w4'
                      "
                      class="text-nowrap"
                      style="text-align: right; background: #1565c0"
                    >
                      <div v-if="data.w4 === '' || data.w4 === null" />

                      <div
                        v-else-if="
                          data.target_report === 'lebihdari' &&
                          parseFloat(data.target) > parseFloat(data.w4)
                        "
                      >
                        <b-badge
                          variant="light-danger"
                          style="font-size: 13px; background-color: red"
                        >
                          <!-- <span class="font-weight-bolder text-danger">{{ data.w4 }}</span>  -->
                          <b-link
                            v-if="data.link_pbi !== null"
                            :to="{
                              name: data.link_pbi,
                              params: { mon: data.mon, week: '4' },
                            }"
                            target="_blank"
                            class=""
                          >
                            {{ data.w4 }}
                          </b-link>

                          <b-link
                            v-else
                            href="#"
                            target="_blank"
                            class=""
                            disabled
                          >
                            {{ data.w4 }}
                          </b-link>
                        </b-badge>
                      </div>
                      <div
                        v-else-if="
                          data.target_report === 'kurangdari' &&
                          parseFloat(data.target) < parseFloat(data.w4)
                        "
                      >
                        <b-badge
                          variant="light-danger"
                          style="font-size: 13px; background-color: red"
                        >
                          <!-- <span class="font-weight-bolder text-danger">{{ data.w4 }}</span>  -->
                          <b-link
                            v-if="data.link_pbi !== null"
                            :to="{
                              name: data.link_pbi,
                              params: { mon: data.mon, week: '4' },
                            }"
                            target="_blank"
                            class=""
                          >
                            {{ data.w4 }}
                          </b-link>

                          <b-link
                            v-else
                            href="#"
                            target="_blank"
                            class=""
                            disabled
                          >
                            {{ data.w4 }}
                          </b-link>
                        </b-badge>
                      </div>
                      <div v-else>
                        <!-- <span class="font-weight-bolder">{{ data.w4 }}</span>  -->
                        <b-badge
                          variant="light-success"
                          style="font-size: 13px; background-color: seagreen"
                        >
                          <b-link
                            v-if="data.link_pbi !== null"
                            :to="{
                              name: data.link_pbi,
                              params: { mon: data.mon, week: '4' },
                            }"
                            target="_blank"
                            class=""
                          >
                            {{ data.w4 }}
                          </b-link>

                          <b-link
                            v-else
                            href="#"
                            target="_blank"
                            class=""
                            disabled
                          >
                            {{ data.w4 }}
                          </b-link>
                        </b-badge>
                      </div>
                    </b-th>

                    <b-th v-else class="text-nowrap" style="text-align: right">
                      <div v-if="data.w4 === '' || data.w4 === null" />

                      <div
                        v-else-if="
                          data.target_report === 'lebihdari' &&
                          parseFloat(data.target) > parseFloat(data.w4)
                        "
                      >
                        <b-badge
                          variant="light-danger"
                          style="font-size: 13px; background-color: red"
                        >
                          <!-- <span class="font-weight-bolder text-danger">{{ data.w4 }}</span>  -->
                          <b-link
                            v-if="data.link_pbi !== null"
                            :to="{
                              name: data.link_pbi,
                              params: { mon: data.mon, week: '4' },
                            }"
                            target="_blank"
                            class=""
                          >
                            {{ data.w4 }}
                          </b-link>

                          <b-link
                            v-else
                            href="#"
                            target="_blank"
                            class=""
                            disabled
                          >
                            {{ data.w4 }}
                          </b-link>
                        </b-badge>
                      </div>
                      <div
                        v-else-if="
                          data.target_report === 'kurangdari' &&
                          parseFloat(data.target) < parseFloat(data.w4)
                        "
                      >
                        <b-badge
                          variant="light-danger"
                          style="font-size: 13px; background-color: red"
                        >
                          <!-- <span class="font-weight-bolder text-danger">{{ data.w4 }}</span>  -->
                          <b-link
                            v-if="data.link_pbi !== null"
                            :to="{
                              name: data.link_pbi,
                              params: { mon: data.mon, week: '4' },
                            }"
                            target="_blank"
                            class=""
                          >
                            {{ data.w4 }}
                          </b-link>

                          <b-link
                            v-else
                            href="#"
                            target="_blank"
                            class=""
                            disabled
                          >
                            {{ data.w4 }}
                          </b-link>
                        </b-badge>
                      </div>
                      <div v-else>
                        <!-- <span class="font-weight-bolder">{{ data.w4 }}</span>  -->
                        <b-badge
                          variant="light-success"
                          style="font-size: 13px; background-color: seagreen"
                        >
                          <b-link
                            v-if="data.link_pbi !== null"
                            :to="{
                              name: data.link_pbi,
                              params: { mon: data.mon, week: '4' },
                            }"
                            target="_blank"
                            class=""
                          >
                            {{ data.w4 }}
                          </b-link>

                          <b-link
                            v-else
                            href="#"
                            target="_blank"
                            class=""
                            disabled
                          >
                            {{ data.w4 }}
                          </b-link>
                        </b-badge>
                      </div>
                    </b-th>
                    <!-- end week 4 bawah -->
                    <!-- start week 5 bawah -->
                    <b-th
                      v-if="
                        WeeklyOPRDetail2.count === 5 &&
                        data.bulan == data.current_month &&
                        data.current_week == 'w5'
                      "
                      class="text-nowrap"
                      style="text-align: right; background: #1565c0"
                    >
                      <div v-if="data.w5 === '' || data.w5 === null" />

                      <div
                        v-else-if="
                          data.target_report === 'lebihdari' &&
                          parseFloat(data.target) > parseFloat(data.w5)
                        "
                      >
                        <b-badge
                          variant="light-danger"
                          style="font-size: 13px; background-color: red"
                        >
                          <!-- <span class="font-weight-bolder text-danger">{{ data.w5 }}</span>  -->
                          <b-link
                            v-if="data.link_pbi !== null"
                            :to="{
                              name: data.link_pbi,
                              params: { mon: data.mon, week: '5' },
                            }"
                            target="_blank"
                            class=""
                          >
                            {{ data.w5 }}
                          </b-link>

                          <b-link
                            v-else
                            href="#"
                            target="_blank"
                            class=""
                            disabled
                          >
                            {{ data.w5 }}
                          </b-link>
                        </b-badge>
                      </div>
                      <div
                        v-else-if="
                          data.target_report === 'kurangdari' &&
                          parseFloat(data.target) < parseFloat(data.w5)
                        "
                      >
                        <b-badge
                          variant="light-danger"
                          style="font-size: 13px; background-color: red"
                        >
                          <b-link
                            v-if="data.link_pbi !== null"
                            :to="{
                              name: data.link_pbi,
                              params: { mon: data.mon, week: '5' },
                            }"
                            target="_blank"
                            class=""
                          >
                            {{ data.w5 }}
                          </b-link>

                          <b-link
                            v-else
                            href="#"
                            target="_blank"
                            class=""
                            disabled
                          >
                            {{ data.w5 }}
                          </b-link>
                        </b-badge>
                      </div>
                      <div v-else>
                        <b-badge
                          variant="light-success"
                          style="font-size: 13px; background-color: seagreen"
                        >
                          <b-link
                            v-if="data.link_pbi !== null"
                            :to="{
                              name: data.link_pbi,
                              params: { mon: data.mon, week: '5' },
                            }"
                            target="_blank"
                            class=""
                          >
                            {{ data.w5 }}
                          </b-link>

                          <b-link
                            v-else
                            href="#"
                            target="_blank"
                            class=""
                            disabled
                          >
                            {{ data.w5 }}
                          </b-link>
                        </b-badge>
                      </div>
                    </b-th>
                    <b-th
                      v-if="
                        WeeklyOPRDetail2.count === 5 &&
                        data.bulan != data.current_month &&
                        data.current_week != 'w5'
                      "
                      class="text-nowrap"
                      style="text-align: right"
                    >
                      <div v-if="data.w5 === '' || data.w5 === null" />

                      <div
                        v-else-if="
                          data.target_report === 'lebihdari' &&
                          parseFloat(data.target) > parseFloat(data.w5)
                        "
                      >
                        <b-badge
                          variant="light-danger"
                          style="font-size: 13px; background-color: red"
                        >
                          <!-- <span class="font-weight-bolder text-danger">{{ data.w5 }}</span>  -->
                          <b-link
                            v-if="data.link_pbi !== null"
                            :to="{
                              name: data.link_pbi,
                              params: { mon: data.mon, week: '5' },
                            }"
                            target="_blank"
                            class=""
                          >
                            {{ data.w5 }}
                          </b-link>

                          <b-link
                            v-else
                            href="#"
                            target="_blank"
                            class=""
                            disabled
                          >
                            {{ data.w5 }}
                          </b-link>
                        </b-badge>
                      </div>
                      <div
                        v-else-if="
                          data.target_report === 'kurangdari' &&
                          parseFloat(data.target) < parseFloat(data.w5)
                        "
                      >
                        <b-badge
                          variant="light-danger"
                          style="font-size: 13px; background-color: red"
                        >
                          <b-link
                            v-if="data.link_pbi !== null"
                            :to="{
                              name: data.link_pbi,
                              params: { mon: data.mon, week: '5' },
                            }"
                            target="_blank"
                            class=""
                          >
                            {{ data.w5 }}
                          </b-link>

                          <b-link
                            v-else
                            href="#"
                            target="_blank"
                            class=""
                            disabled
                          >
                            {{ data.w5 }}
                          </b-link>
                        </b-badge>
                      </div>
                      <div v-else>
                        <b-badge
                          variant="light-success"
                          style="font-size: 13px; background-color: seagreen"
                        >
                          <b-link
                            v-if="data.link_pbi !== null"
                            :to="{
                              name: data.link_pbi,
                              params: { mon: data.mon, week: '5' },
                            }"
                            target="_blank"
                            class=""
                          >
                            {{ data.w5 }}
                          </b-link>

                          <b-link
                            v-else
                            href="#"
                            target="_blank"
                            class=""
                            disabled
                          >
                            {{ data.w5 }}
                          </b-link>
                        </b-badge>
                      </div>
                    </b-th>
                    <b-th
                      v-if="
                        WeeklyOPRDetail2.count === 5 &&
                        data.bulan == data.current_month &&
                        data.current_week != 'w5'
                      "
                      class="text-nowrap"
                      style="text-align: right"
                    >
                      <div v-if="data.w5 === '' || data.w5 === null" />

                      <div
                        v-else-if="
                          data.target_report === 'lebihdari' &&
                          parseFloat(data.target) > parseFloat(data.w5)
                        "
                      >
                        <b-badge
                          variant="light-danger"
                          style="font-size: 13px; background-color: red"
                        >
                          <!-- <span class="font-weight-bolder text-danger">{{ data.w5 }}</span>  -->
                          <b-link
                            v-if="data.link_pbi !== null"
                            :to="{
                              name: data.link_pbi,
                              params: { mon: data.mon, week: '5' },
                            }"
                            target="_blank"
                            class=""
                          >
                            {{ data.w5 }}
                          </b-link>

                          <b-link
                            v-else
                            href="#"
                            target="_blank"
                            class=""
                            disabled
                          >
                            {{ data.w5 }}
                          </b-link>
                        </b-badge>
                      </div>
                      <div
                        v-else-if="
                          data.target_report === 'kurangdari' &&
                          parseFloat(data.target) < parseFloat(data.w5)
                        "
                      >
                        <b-badge
                          variant="light-danger"
                          style="font-size: 13px; background-color: red"
                        >
                          <b-link
                            v-if="data.link_pbi !== null"
                            :to="{
                              name: data.link_pbi,
                              params: { mon: data.mon, week: '5' },
                            }"
                            target="_blank"
                            class=""
                          >
                            {{ data.w5 }}
                          </b-link>

                          <b-link
                            v-else
                            href="#"
                            target="_blank"
                            class=""
                            disabled
                          >
                            {{ data.w5 }}
                          </b-link>
                        </b-badge>
                      </div>
                      <div v-else>
                        <b-badge
                          variant="light-success"
                          style="font-size: 13px; background-color: seagreen"
                        >
                          <b-link
                            v-if="data.link_pbi !== null"
                            :to="{
                              name: data.link_pbi,
                              params: { mon: data.mon, week: '5' },
                            }"
                            target="_blank"
                            class=""
                          >
                            {{ data.w5 }}
                          </b-link>

                          <b-link
                            v-else
                            href="#"
                            target="_blank"
                            class=""
                            disabled
                          >
                            {{ data.w5 }}
                          </b-link>
                        </b-badge>
                      </div>
                    </b-th>
                    <!-- end week 5 bawah  -->
                    <b-th
                      v-if="
                        data.rumus_mtd === 'Summary' && WeeklyOPR.count === 4
                      "
                      style="text-align: right"
                      class="text-nowrap"
                    >
                      <div
                        v-if="
                          data.target_report === 'lebihdari' &&
                          parseFloat(data.w1) +
                            parseFloat(data.w2) +
                            parseFloat(data.w3) +
                            parseFloat(data.w4) >
                            data.target
                        "
                      >
                        <b-badge
                          style="font-size: 13px; background-color: seagreen"
                        >
                          {{
                            (
                              parseFloat(data.w1) +
                              parseFloat(data.w2) +
                              parseFloat(data.w3) +
                              parseFloat(data.w4)
                            ).toFixed(2)
                          }}
                        </b-badge>
                      </div>
                      <div
                        v-else-if="
                          data.target_report === 'kurangdari' &&
                          parseFloat(data.w1) +
                            parseFloat(data.w2) +
                            parseFloat(data.w3) +
                            parseFloat(data.w4) <
                            data.target
                        "
                      >
                        <b-badge
                          style="font-size: 13px; background-color: seagreen"
                          >{{
                            (
                              parseFloat(data.w1) +
                              parseFloat(data.w2) +
                              parseFloat(data.w3) +
                              parseFloat(data.w4)
                            ).toFixed(2)
                          }}
                        </b-badge>
                      </div>
                      <div v-else>
                        <b-badge style="font-size: 13px; background-color: red">
                          {{
                            (
                              parseFloat(data.w1) +
                              parseFloat(data.w2) +
                              parseFloat(data.w3) +
                              parseFloat(data.w4)
                            ).toFixed(2)
                          }}
                        </b-badge>
                      </div>
                    </b-th>
                    <b-th
                      v-else-if="
                        data.rumus_mtd === 'Summary' && WeeklyOPR.count === 5
                      "
                      style="text-align: right"
                      class="text-nowrap"
                    >
                      <div
                        v-if="
                          data.target_report === 'lebihdari' &&
                          parseFloat(data.w1) +
                            parseFloat(data.w2) +
                            parseFloat(data.w3) +
                            parseFloat(data.w4) +
                            parseFloat(data.w5) >
                            data.target
                        "
                      >
                        <b-badge
                          style="font-size: 13px; background-color: seagreen"
                        >
                          {{
                            (
                              parseFloat(data.w1) +
                              parseFloat(data.w2) +
                              parseFloat(data.w3) +
                              parseFloat(data.w4) +
                              parseFloat(data.w5)
                            ).toFixed(2)
                          }}
                        </b-badge>
                      </div>
                      <div
                        v-else-if="
                          data.target_report === 'kurangdari' &&
                          parseFloat(data.w1) +
                            parseFloat(data.w2) +
                            parseFloat(data.w3) +
                            parseFloat(data.w4) +
                            parseFloat(data.w5) <
                            data.target
                        "
                      >
                        <b-badge
                          style="font-size: 13px; background-color: seagreen"
                        >
                          {{
                            (
                              parseFloat(data.w1) +
                              parseFloat(data.w2) +
                              parseFloat(data.w3) +
                              parseFloat(data.w4) +
                              parseFloat(data.w5)
                            ).toFixed(2)
                          }}
                          xx
                        </b-badge>
                      </div>
                      <div v-else>
                        <b-badge style="font-size: 13px; background-color: red">
                          {{
                            (
                              parseFloat(data.w1) +
                              parseFloat(data.w2) +
                              parseFloat(data.w3) +
                              parseFloat(data.w4) +
                              parseFloat(data.w5)
                            ).toFixed(2)
                          }}
                        </b-badge>
                      </div>
                    </b-th>
                    <b-th
                      v-else-if="
                        data.rumus_mtd === 'Average' && WeeklyOPR.count === 4
                      "
                      style="text-align: right"
                      class="text-nowrap"
                    >
                      <div
                        v-if="
                          data.target_report === 'lebihdari' &&
                          (parseFloat(data.w1) +
                            parseFloat(data.w2) +
                            parseFloat(data.w3) +
                            parseFloat(data.w4)) /
                            4 >=
                            data.target
                        "
                      >
                        <b-badge
                          style="font-size: 13px; background-color: seagreen"
                        >
                          {{
                            (
                              (parseFloat(data.w1) +
                                parseFloat(data.w2) +
                                parseFloat(data.w3) +
                                parseFloat(data.w4)) /
                              4
                            ).toFixed(2)
                          }}
                        </b-badge>
                      </div>
                      <div
                        v-else-if="
                          data.target_report === 'kurangdari' &&
                          (parseFloat(data.w1) +
                            parseFloat(data.w2) +
                            parseFloat(data.w3) +
                            parseFloat(data.w4)) /
                            4 <=
                            data.target
                        "
                      >
                        <b-badge
                          style="font-size: 13px; background-color: seagreen"
                        >
                          {{
                            (
                              (parseFloat(data.w1) +
                                parseFloat(data.w2) +
                                parseFloat(data.w3) +
                                parseFloat(data.w4)) /
                              4
                            ).toFixed(2)
                          }}
                        </b-badge>
                      </div>
                      <div v-else>
                        <b-badge style="font-size: 13px; background-color: red">
                          {{
                            (
                              (parseFloat(data.w1) +
                                parseFloat(data.w2) +
                                parseFloat(data.w3) +
                                parseFloat(data.w4)) /
                              4
                            ).toFixed(2)
                          }}
                        </b-badge>
                      </div>
                    </b-th>
                    <b-th
                      v-else-if="
                        data.rumus_mtd === 'Average' && WeeklyOPR.count === 5
                      "
                      style="text-align: right"
                      class="text-nowrap"
                    >
                      <div
                        v-if="
                          data.target_report === 'lebihdari' &&
                          (parseFloat(data.w1) +
                            parseFloat(data.w2) +
                            parseFloat(data.w3) +
                            parseFloat(data.w4) +
                            parseFloat(data.w5)) /
                            5 >=
                            data.target
                        "
                      >
                        <b-badge
                          style="font-size: 13px; background-color: seagreen"
                        >
                          {{
                            (
                              (parseFloat(data.w1) +
                                parseFloat(data.w2) +
                                parseFloat(data.w3) +
                                parseFloat(data.w4) +
                                parseFloat(data.w5)) /
                              5
                            ).toFixed(2)
                          }}
                        </b-badge>
                      </div>
                      <div
                        v-else-if="
                          data.target_report === 'kurangdari' &&
                          (parseFloat(data.w1) +
                            parseFloat(data.w2) +
                            parseFloat(data.w3) +
                            parseFloat(data.w4) +
                            parseFloat(data.w5)) /
                            5 <=
                            data.target
                        "
                      >
                        <b-badge
                          style="font-size: 13px; background-color: seagreen"
                        >
                          {{
                            (
                              (parseFloat(data.w1) +
                                parseFloat(data.w2) +
                                parseFloat(data.w3) +
                                parseFloat(data.w4) +
                                parseFloat(data.w5)) /
                              5
                            ).toFixed(2)
                          }}
                        </b-badge>
                      </div>
                      <div v-else>
                        <b-badge style="font-size: 13px; background-color: red">
                          {{
                            (
                              (parseFloat(data.w1) +
                                parseFloat(data.w2) +
                                parseFloat(data.w3) +
                                parseFloat(data.w4) +
                                parseFloat(data.w5)) /
                              5
                            ).toFixed(2)
                          }}
                        </b-badge>
                      </div>
                    </b-th>
                    <b-th class="text-nowrap" style="background: top">
                      <pre style="background: fixed">{{ data.remark }}</pre>
                    </b-th>
                    <b-th
                      class="text-nowrap"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        border-left: hidden;
                      "
                      ><feather-icon
                        icon="EditIcon"
                        class="cursor-pointer"
                        :class="`text-primary`"
                        size="17"
                        @click="EditData(data)"
                      />
                    </b-th>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </app-collapse-item>
          </app-collapse>
        </div>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BSpinner,
  BCard,
  BCardBody,
  BTableSimple,
  BTr,
  BTbody,
  BTfoot,
  BButton,
  BBadge,
  BThead,
  BLink,
  BTh,
  BTd,
  BFormTextarea,
} from "bootstrap-vue";
import store from "@/store";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
// eslint-disable-next-line import/no-cycle
import { avatarText } from "@core/utils/filter";
import Ripple from "vue-ripple-directive";
import axios from "axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import useAppConfig from "@core/app-config/useAppConfig";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import ListFilters from "./ListFilters.vue";
// eslint-disable-next-line import/no-cycle
import useList from "./useList";
import StoreModule from "../StoreModule";
import AddNew from "./AddNew.vue";

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    ListFilters,
    AddNew,

    BCard,
    BTableSimple,
    BTr,
    BTbody,
    BTfoot,
    BSpinner,
    BButton,
    BCardBody,
    BBadge,
    BThead,
    BTh,
    BTd,
    AppCollapse,
    AppCollapseItem,
    BLink,
    BFormTextarea,
    // BDropdown,
    // BDropdownItem,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isBusy: false,
      collapseType: "default",
    };
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-user";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, StoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const WeeklyOPR = ref([]);
    onMounted(async () => {
      const result = await axios.post(
        `${useAppConfig().AxiosUrl}api_mes/weekly-opr-header/view`
      );
      WeeklyOPR.value = result.data;
    });

    const WeeklyOPRDetail = ref([]);
    onMounted(async () => {
      const result = await axios.post(
        `${useAppConfig().AxiosUrl}api_mes/weekly-opr-detail/view`
      );
      WeeklyOPRDetail.value = result.data;
    });

    const WeeklyOPRDetail2 = ref([]);
    onMounted(async () => {
      const result = await axios.post(
        `${useAppConfig().AxiosUrl}api_mes/weekly-opr-detail2/view`
      );
      WeeklyOPRDetail2.value = result.data;
    });

    const blankTask = {
      remark: "",
    };

    const isAddNewUserSidebarActive = ref(false);
    const userData = ref(JSON.parse(JSON.stringify(blankTask)));

    const roleOptions = ref([]);

    const moldOptions = ref([]);

    const imOptions = ref([]);

    const EditData = (taskData) => {
      userData.value = taskData;
      userData.value.user = localStorage.getItem("nik");
      isAddNewUserSidebarActive.value = true;
    };

    const AddData = () => {
      userData.value = blankTask;
      isAddNewUserSidebarActive.value = true;
    };

    const {
      fetchData,
      tableColumns,
      perPage,
      currentPage,
      totalData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      imFilter,
      moldFilter,
      planFilter,
      statusFilter,
    } = useList();

    return {
      userData,
      AddData,
      EditData,

      // Sidebar
      isAddNewUserSidebarActive,
      // SimulatorData,
      WeeklyOPR,
      WeeklyOPRDetail,
      WeeklyOPRDetail2,

      fetchData,
      tableColumns,
      perPage,
      currentPage,
      totalData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      roleOptions,
      imOptions,
      moldOptions,

      // Extra Filters
      roleFilter,
      imFilter,
      moldFilter,
      planFilter,
      statusFilter,
    };
  },
  methods: {
    async ShowData() {
      const params = new URLSearchParams();
      params.append("filter", this.statusFilter);

      const result = await axios.post(
        `${useAppConfig().AxiosUrl}api_mes/weekly-opr-header/view`,
        params
      );
      this.WeeklyOPR = result.data;

      const result2 = await axios.post(
        `${useAppConfig().AxiosUrl}api_mes/weekly-opr-detail/view`,
        params
      );
      this.WeeklyOPRDetail = result2.data;

      const result3 = await axios.post(
        `${useAppConfig().AxiosUrl}api_mes/weekly-opr-detail2/view`,
        params
      );
      this.WeeklyOPRDetail2 = result3.data;
    },
    async refreshdata() {
      const result = await axios.get(
        `${useAppConfig().AxiosUrl}api_mes/mold-simulator/view`
      );
      const dataAPI = result.data.data;
      const combo = [];
      // eslint-disable-next-line no-plusplus
      for (let i = 0, len = dataAPI.length; i < len; i++) {
        combo.push({
          label: `${dataAPI[i].machine} (${dataAPI[i].mold})`,
          value: dataAPI[i].machine,
        });
      }
      this.imOptions = combo;

      // const result2 = await axios.get(`${useAppConfig().AxiosUrl}api_mes/mold-simulator/view`)
      // this.SimulatorData = result2.data

      const result3 = await axios.get(
        `${useAppConfig().AxiosUrl}api_mes/mold-simulator-total/view`
      );
      this.WeeklyOPR = result3.data;
    },
    async showMsgBoxTwo(id, periode) {
      console.log(id);
      this.boxTwo = id;
      this.$bvModal
        .msgBoxConfirm("Are You Sure To Delete This Data?", {
          title: "Please Confirm",
          size: "sm",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: true,
          centered: true,
        })
        .then(async (value) => {
          this.boxTwo = value;
          if (value === true) {
            const params = new URLSearchParams();
            params.append("param", "delete");
            params.append("group_item", id);
            params.append("periode", periode);
            const result = await axios.post(
              `${useAppConfig().AxiosUrl}api_mes/stok-akhir-ctr/delete`,
              params
            );
            console.log(result);
            if (result.data.message === "Data Deleted") {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Notification",
                  icon: "BellIcon",
                  text: result.data.message,
                  variant: "success",
                },
              });
              this.refetchData();
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  text: "Error Deleting Data",
                  variant: "danger",
                },
              });
              console.log(result);
            }
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
